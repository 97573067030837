.header-view {
	.header {
		position: relative;
		height: 80px;
		padding: 0 112px;

		.menuList {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.menuItem {
				&:not(:first-child) {
					margin-left: 32px;
				}
			}
		}
	}
}

.dashboard {
	margin-left: 70px;
	width: 154px;
	height: 36px;
	border: 1px solid #565656;
	border-radius: 9px;
}

@media only screen and (max-width: 1080px) {
	.drawerBox {
		width: 100%;
		height: 100%;
		background: #141316;
		border-right: 5px solid #BDF56C;
		padding: 0 16px;
		position: relative;

		.drawerHeader {
			height: 80px;
			padding-left: 8px;
			padding-right: 14px;
		}
	}

	.dashboard {
		margin-left: 0;
		width: 168px;
	}

	.menuList-m {
		margin-top: 60px;
		line-height: 50px;
	}

	.footerContainer {
		position: absolute;
		bottom: 0;
		width: 100vw;
		height: 90px;
		.line {
			width: 52px;
			height: 2px;
			background: #FFFFFF;
		}

		.circle {
			width: 6px;
			height: 6px;
			background: #FFFFFF;
			border-radius: 50%;
		}
	}
}