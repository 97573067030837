* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Sulphur-Point-Regular";
  font-weight: 700;
  transition: all ease 0.33ms;
  color: #FFFFFF;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-44 {
  margin-top: 44px;
}

.font-12 {
	font-size: 12px;
}

.font-13 {
	font-size: 13px;
}

.font-14 {
	font-size: 14px;
}

.font-16 {
	font-size: 16px;
}

.font-20 {
	font-size: 20px;
}

.font-24 {
	font-size: 24px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 36px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: #565656;
}

.color3 {
  color: #0E0E0E;
}

.color4 {
  color: #F6EA5F;
}

.color5 {
  color: #BDF56C;
}

.color6 {
  color: rgba(255, 255, 255, 0.2);
}

.font-weight-b {
  // font-weight: 700;
  font-variation-settings: "wght" 700;
}

.border-radius-4 {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none;
}

.word-break {
  word-break: break-word
}

.app-container {
	width: 100vw;
	height: 100vh;
	background: url(./assets/images/starts.png);
	background-repeat: no-repeat;
	background-size: cover;
}

@media only screen and (max-width: 1080px) {
  .container {
    width: 375px;
  }
}